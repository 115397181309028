import * as React from "react";
import { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import PrepCarousel from "./prep-carousel.js";

class Content extends Component {
  render() {
    return (
      <div className={`${this.props.show ? "d-block" : "d-none"}`}>
        <Container
          fluid
          className="hero prep-basics"
          role="img"
          aria-label="Group of people gathered together in a series of images"
        />

        <Container className="prep-basics">
          <Row className="mb-4">
            <Col xs={9} lg={10} className="m-auto px-0">
              <div className="hero-callout">
                <h1 className="title">
                  <span class="sub-title d-block">Downloadable Resource</span>
                  P<span class="text-lowercase">r</span>EP Matters:{" "}
                  <br class="d-block d-sm-none" />A Guide to{" "}
                  <br class="d-block d-sm-none" />Advancing Uptake
                </h1>
              </div>
              <p className="mb-0">
                Check out "PrEP Matters: A Guide to Advancing Uptake." This resource is intended for allied healthcare professionals and reviews the fundamentals of pre-exposure prophylaxis (PrEP) and offers sample scripts for how to implement effective PrEP discussions at the point of HIV and STI testing. The training also explores how to establish routine PrEP initiation conversations and set goals for advancing PrEP uptake within your organizations.
              </p>
            </Col>
          </Row>
          <Row className="carousel">
            <Col xs={12} lg={10} className="m-auto px-0">
              <PrepCarousel />
              <Col xs={9} lg={12} className="mx-auto">
                <Row className="mb-4">
                  <p className="font-italic text-center cta mb-0">
                  This resource is intended for allied healthcare professionals to help practice effective counseling on HIV prevention options, including PrEP, and help drive change in HIV prevention care.
                  </p>
                </Row>
              </Col>
              <a
                className="d-block mx-auto text-center download-btn"
                target="_blank"
                href="/prep-fundamentals.pdf"
              >
                <span className="btn red text-uppercase mt-0">
                  Download the
                  <br class="d-block d-md-none" /> full presentation
                </span>
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Content;
