import * as React from "react";
import { Component } from "react";
import Carousel from "react-bootstrap/Carousel";
import Container from "react-bootstrap/Container";
import HeroBackground1 from "../../images/hero-background-1.png";
import HeroBackground1Mobile from "../../images/hero-background-1-mobile.png";
import WhatWeLiveFor from "../../images/what-we-live-for.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HeroBlindAngels from "../../images/homepage-blind-angels-hero.png";
import HeroBlindAngelsMobile from "../../images/homepage-blind-angels-hero-mobile.png";

class PrepCarousel extends Component {
  constructor() {
    super();
    this.state = {
      show: false,
    };
  }
  handleClose = (show) => {
    this.setState({ show: false });
  };
  handleClose = (show) => {
    this.setState({ show: false });
  };
  handleShow = (show) => {
    this.setState({ show: true });
  };
  render() {
    return (
      <Container className="px-0 mb-4">
        <Row>
          <Col>
            <Carousel interval={7000} keyboard={true}>
              <Carousel.Item>
                <img
                  className="d-none d-md-block w-100"
                  src={"/prep-carousel/GileadHIV_PrEP_2024_Deck_1.jpg"}
                  alt="GileadHIV_PrEP 2024 Deck 1"
                />
                <img
                  className="d-block d-md-none w-100"
                  src={"/prep-carousel/GileadHIV_PrEP_2024_Deck_1.jpg"}
                  alt="GileadHIV_PrEP 2024 Deck 1"
                />
                {/*
                <Carousel.Caption className="">
                  <Container className="h-100">
                    <Row className="h-100">
                      <Col
                        xs={{ span: 8 }}
                        md={{ span: 6, offset: 1 }}
                        className="pl-2 px-md-0"
                      >
                        <a className="no-interstitial nodecor">
                          <div className="cont-slide">
                            <h2 className="d-none d-lg-block font-heavy line-height-25-mob text-uppercase">
                              TOGETHER WE HONOR <br />
                              WORLD AIDS DAY
                            </h2>
                            <h2 className="d-block d-lg-none font-heavy line-height-25-mob text-uppercase">
                              HONORING WORLD AIDS DAY
                            </h2>
                            <p className="d-none d-lg-block">
                              Since 1988, World AIDS Day has been an opportunity
                              to commemorate the lives lost to AIDS-related
                              illnesses and recommit ourselves to taking action.
                              This year, the theme is “World AIDS Day 35:
                              Remember and Commit.” Join us in showing your
                              commitment this year by downloading this free
                              social media toolkit to share with your community.
                            </p>
                            <a
                              className="slide-anchor open_isi"
                              href="/community-involvement/social-toolkit"
                            >
                              <span className="btn red">
                                DOWNLOAD <br className="d-block d-md-none" />
                                FREE GRAPHICS
                              </span>
                            </a>
                          </div>
                        </a>
                      </Col>
                    </Row>
                  </Container>
                </Carousel.Caption> */}
              </Carousel.Item>

              <Carousel.Item>
                <img
                  className="d-none d-md-block w-100"
                  src={"/prep-carousel/GileadHIV_PrEP_2024_Deck_2.jpg"}
                  alt="GileadHIV_PrEP 2024 Deck 2"
                />
                <img
                  className="d-block d-md-none w-100"
                  src={"/prep-carousel/GileadHIV_PrEP_2024_Deck_2.jpg"}
                  alt="GileadHIV_PrEP 2024 Deck 2"
                />
              </Carousel.Item>

              <Carousel.Item>
                <img
                  className="d-none d-md-block w-100"
                  src={"/prep-carousel/GileadHIV_PrEP_2024_Deck_3.jpg"}
                  alt="GileadHIV_PrEP 2024 Deck 3"
                />
                <img
                  className="d-block d-md-none w-100"
                  src={"/prep-carousel/GileadHIV_PrEP_2024_Deck_3.jpg"}
                  alt="GileadHIV_PrEP 2024 Deck 3"
                />
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default PrepCarousel;
