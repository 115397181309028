import * as React from "react";
import { Component } from "react";
import Layout from "../../components/global/layout.js";
import Content from "../../components/downloadable-resource/content.js";
import "@fortawesome/fontawesome-free/css/all.css";
import "../../sass/app.scss";

class PrepBasics extends Component {
  render() {
    return (
      <>
        <Layout section="orphan" page="prep-basics">
          <Content section="orphan" page="prep-basics" />
        </Layout>
      </>
    );
  }
}

export default PrepBasics;
